import { YouTube } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import useMediaQuery from "@mui/material/useMediaQuery";
import { motion } from "framer-motion";
import React, { useContext, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { MenuContext } from "../../contexts/menu-context";
import "./header.scss";

const navItems = [
  {
    name: "Napelem",
    path: "#napelemes_rendszerek",
  },
  {
    name: "Energiatároló",
    path: "#energiatarolo",
  },
  {
    name: "Szolgáltatásaink",
    path: "#szolgaltatasaink",
  },
  {
    name: "Távfelügyelet",
    path: "#tavfelugyelet",
  },
  {
    name: "Blogok",
    path: "#blogok",
  },
  {
    name: "Kapcsolat",
    path: "#kapcsolat",
  },
];

const Header = () => {
  const { isDark } = useContext(MenuContext);
  const headerRef = useRef();
  const isTablet = useMediaQuery("(max-width: 1215px)");

  const scrollTopHandler = () => {
    if (window.location.pathname === "/") {
      window.scrollTo({ left: 0, top: 0 });
    } else {
      window.location.href = "/";
    }
  };

  useEffect(() => {
    const scrollHandler = () => {
      if (location.pathname !== "/f9a3c2dd20d43eda5d3e522ba2b79dfc") {
        if (
          document.body.scrollTop > 100 ||
          document.documentElement.scrollTop > 100
        ) {
          headerRef.current.classList.add("scroll");
        } else {
          headerRef.current.classList.remove("scroll");
        }
      }
    };

    window.addEventListener("scroll", scrollHandler);

    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  });

  const location = useLocation();

  return location.pathname === "/f9a3c2dd20d43eda5d3e522ba2b79dfc" ? (
    <></>
  ) : (
    <div
      ref={headerRef}
      className="header"
      style={isDark ? { color: "#fff" } : {}}>
      <div className="header__content">
        <div className="header__content__logo">
          <span onClick={scrollTopHandler}>
            <svg
              id="Layer_2"
              data-name="Layer 2"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 315 64.62">
              <g id="Layer_1-2" data-name="Layer 1">
                <g>
                  <path d="m72.64,59.78c-.21.35-.48.61-.82.78-.34.17-.72.26-1.12.26-.36,0-.7-.05-1-.17-.31-.12-.58-.28-.8-.5-.23-.22-.4-.49-.53-.81-.13-.32-.2-.68-.2-1.09,0-.3.05-.6.15-.91.1-.3.25-.58.46-.83.21-.25.47-.45.79-.61.32-.16.7-.24,1.13-.24.29,0,.56.04.81.11.25.08.48.19.67.34.19.15.36.33.5.55.14.22.24.48.3.77h-.46c-.05-.22-.13-.42-.24-.59-.11-.16-.25-.3-.41-.41-.16-.11-.34-.2-.54-.25-.2-.06-.41-.09-.64-.09-.32,0-.61.06-.86.17-.25.11-.47.27-.65.46-.18.2-.32.42-.41.68-.1.26-.15.54-.15.82s.04.56.13.82c.09.26.22.48.39.68.17.2.39.35.65.46.26.11.56.17.9.17.27,0,.52-.04.74-.12.22-.08.42-.2.58-.35.16-.15.3-.34.39-.56.1-.22.16-.47.18-.75h-1.84v-.43h2.31v2.55h-.42v-.93Z" />
                  <path d="m78.75,58.3c.22.08.38.21.49.39.11.18.17.39.18.62,0,.22,0,.41.02.55,0,.15.01.27.03.38.01.11.03.19.05.26.02.07.05.15.08.21h-.45c-.07-.07-.12-.2-.13-.38-.02-.19-.03-.46-.04-.81,0-.19-.02-.35-.06-.48-.03-.13-.09-.23-.17-.3-.08-.07-.19-.13-.33-.16-.14-.03-.32-.04-.54-.04h-1.96v2.18h-.45v-4.94h2.42c.22,0,.43.02.63.06.2.04.37.11.51.21.15.1.27.24.35.41.09.18.13.39.13.66,0,.23-.06.46-.18.68-.12.21-.31.38-.56.5h0Zm-.86-2.09h-2v1.89h1.92c.07,0,.17,0,.3-.01.14,0,.27-.04.41-.11.13-.06.25-.16.36-.3.1-.14.15-.33.15-.58,0-.17-.03-.32-.09-.44-.06-.11-.14-.21-.24-.27-.11-.07-.23-.11-.36-.14-.14-.03-.29-.04-.45-.04h0Z" />
                  <path d="m81.7,60.71v-4.94h3.76v.44h-3.31v1.73h3.18v.43h-3.18v1.9h3.35v.44h-3.8Z" />
                  <path d="m87.57,60.71v-4.94h3.77v.44h-3.31v1.73h3.18v.43h-3.18v1.9h3.35v.44h-3.81Z" />
                  <path d="m97.27,59.98v-4.21h.45v4.94h-.49l-3.32-4.21v4.21h-.45v-4.94h.49l3.32,4.21Z" />
                  <rect x="102.99" y="55.77" width=".44" height="4.94" />
                  <path d="m107.7,60.82c-.38,0-.71-.04-.98-.12-.27-.09-.5-.21-.67-.37-.17-.16-.3-.36-.38-.59-.08-.23-.12-.49-.12-.78h.45c0,.23.03.43.09.61.06.18.16.33.29.45.13.12.31.21.52.27.21.06.47.09.77.09.28,0,.51-.03.7-.09.19-.06.34-.13.45-.22.11-.09.2-.2.24-.31.05-.11.07-.24.07-.36s-.02-.22-.04-.31c-.03-.09-.09-.17-.17-.24-.09-.07-.2-.13-.35-.19-.14-.05-.34-.11-.58-.16l-.85-.18c-.25-.05-.47-.12-.66-.19-.18-.07-.33-.16-.45-.27-.12-.1-.21-.23-.26-.37-.06-.14-.08-.31-.08-.5s.04-.37.12-.54c.09-.16.2-.3.36-.42.15-.12.34-.21.57-.27.23-.07.48-.1.77-.1s.56.03.8.09c.24.06.44.16.62.29.17.13.31.3.41.5.1.2.15.45.16.75h-.46c0-.14-.03-.29-.08-.43-.04-.14-.12-.27-.23-.38-.11-.11-.26-.21-.44-.27-.18-.07-.42-.11-.71-.11-.27,0-.5.02-.68.07-.18.05-.33.11-.43.2-.11.08-.18.17-.22.28-.04.11-.07.22-.07.34,0,.11.01.21.04.3.02.09.07.16.15.23.07.07.18.13.31.18.14.05.31.1.52.15l.94.19c.23.05.44.11.62.18.18.07.34.15.46.25.13.1.23.23.3.38.07.15.1.33.1.53,0,.17-.03.35-.09.52-.06.17-.17.33-.32.46-.15.14-.35.25-.59.33s-.56.12-.92.12h0Z" />
                  <path d="m111.76,60.71v-4.94h.45v4.5h2.98v.44h-3.43Z" />
                  <path d="m120.92,60.71l-.68-1.54h-2.44l-.68,1.54h-.52l2.19-4.94h.44l2.19,4.94h-.51Zm-1.9-4.3l-1.03,2.33h2.05l-1.03-2.33Z" />
                  <path d="m127.09,59.98v-4.21h.45v4.94h-.5l-3.32-4.21v4.21h-.45v-4.94h.49l3.32,4.21Z" />
                  <path d="m132.1,60.71h-2.18v-4.94h2.23c.28,0,.55.04.8.12.26.08.49.21.68.41.19.19.35.44.47.76.12.32.18.71.18,1.19,0,.36-.04.68-.13.99-.09.3-.22.56-.4.78-.18.22-.41.39-.68.52-.28.12-.6.18-.97.18h0Zm-.02-4.5h-1.71v4.06h1.78c.24,0,.46-.04.67-.12.21-.08.38-.2.53-.36.15-.16.27-.38.35-.63.08-.26.12-.56.12-.92s-.04-.68-.12-.93c-.09-.26-.2-.47-.36-.63-.15-.16-.34-.28-.56-.36-.21-.08-.45-.11-.71-.11h0Z" />
                  <rect x="139.45" y="55.77" width=".44" height="4.94" />
                  <path d="m146.04,59.98v-4.21h.45v4.94h-.5l-3.32-4.21v4.21h-.45v-4.94h.49l3.32,4.21Z" />
                  <path d="m150.91,60.71h-.44l-2.11-4.94h.51l1.82,4.26,1.82-4.26h.51l-2.11,4.94Z" />
                  <path d="m154.88,60.71v-4.94h3.76v.44h-3.31v1.73h3.18v.43h-3.18v1.9h3.35v.44h-3.8Z" />
                  <path d="m162.69,60.82c-.38,0-.71-.04-.98-.12s-.5-.21-.67-.37-.3-.36-.38-.59c-.08-.23-.12-.49-.12-.78h.45c0,.23.03.43.09.61.06.18.16.33.29.45.13.12.31.21.52.27.21.06.47.09.77.09.28,0,.51-.03.7-.09.19-.06.34-.13.45-.22.11-.09.19-.2.24-.31.05-.11.07-.24.07-.36s-.02-.22-.05-.31c-.03-.09-.08-.17-.17-.24-.09-.07-.2-.13-.35-.19-.15-.05-.34-.11-.58-.16l-.85-.18c-.25-.05-.47-.12-.66-.19-.18-.07-.33-.16-.45-.27-.12-.1-.21-.23-.26-.37-.05-.14-.08-.31-.08-.5s.04-.37.12-.54c.08-.16.2-.3.35-.42.15-.12.34-.21.57-.27.23-.07.48-.1.77-.1s.56.03.8.09c.24.06.45.16.62.29.17.13.31.3.41.5.1.2.15.45.16.75h-.46c0-.14-.04-.29-.08-.43-.05-.14-.12-.27-.23-.38-.11-.11-.26-.21-.44-.27-.18-.07-.42-.11-.71-.11s-.5.02-.68.07c-.18.05-.32.11-.43.2-.11.08-.18.17-.23.28-.04.11-.06.22-.06.34,0,.11.02.21.04.3.03.09.08.16.15.23.08.07.18.13.31.18.14.05.31.1.52.15l.94.19c.23.05.44.11.62.18.18.07.34.15.46.25.13.1.23.23.3.38.07.15.1.33.1.53,0,.17-.03.35-.09.52-.06.17-.17.33-.32.46-.15.14-.35.25-.59.33-.25.09-.55.12-.92.12h0Z" />
                  <path d="m168.55,56.21v4.5h-.45v-4.5h-1.85v-.44h4.15v.44h-1.85Z" />
                  <path d="m174.76,60.71v-.44l3.5-4.06h-3.29v-.44h3.88v.43l-3.5,4.07h3.55v.44h-4.15Z" />
                  <path d="m184.14,58.3c.22.08.38.21.49.39.11.18.17.39.18.62,0,.22.01.41.02.55,0,.15.02.27.03.38.01.11.03.19.05.26.02.07.04.15.08.21h-.45c-.07-.07-.11-.2-.13-.38-.02-.19-.03-.46-.04-.81,0-.19-.02-.35-.06-.48-.03-.13-.09-.23-.17-.3-.08-.07-.2-.13-.34-.16-.14-.03-.32-.04-.54-.04h-1.96v2.18h-.45v-4.94h2.43c.22,0,.43.02.63.06.19.04.37.11.51.21.15.1.26.24.35.41.09.18.13.39.13.66,0,.23-.06.46-.18.68-.12.21-.31.38-.56.5h0Zm-.86-2.09h-2v1.89h1.92c.07,0,.17,0,.3-.01.13,0,.27-.04.41-.11.14-.06.25-.16.36-.3.1-.14.16-.33.16-.58,0-.17-.03-.32-.09-.44-.05-.11-.14-.21-.24-.27-.11-.07-.23-.11-.36-.14-.14-.03-.29-.04-.45-.04h0Z" />
                  <path d="m188.88,56.21v4.5h-.45v-4.5h-1.85v-.44h4.15v.44h-1.85Z" />
                  <rect x="192.71" y="59.88" width=".35" height=".84" />
                  <path d="m76.01,11.43v2.7h3.09c-.4,1.95-1.64,2.98-3.41,2.98-2.16,0-3.89-1.66-3.89-5.48,0-3.38,1.7-5.26,3.94-5.26,1.86,0,2.94.95,3.29,2.72l.04.22h3.45l-.02-.22c-.31-3.01-2.48-5.29-6.72-5.29s-7.63,2.9-7.63,8.03,2.77,7.85,6.92,7.85c1.66,0,2.76-.33,3.62-1.13l.69-1.59.26,2.41h2.74v-7.94h-6.39Z" />
                  <path d="m96.84,15.59c-.18-1.77-.77-2.98-2.32-3.56,1.92-.55,3.03-1.81,3.03-3.76,0-2.68-1.84-3.78-4.05-4.05-.79-.09-1.39-.11-2.3-.11h-5.8v15.26h3.47v-5.99h1.37c.42,0,.8,0,1.22.02,1.15.09,1.79,1.06,1.99,2.72.13,1.29.35,2.19.73,3.25h3.78c-.62-.88-.93-1.99-1.13-3.78h0Zm-7.96-4.75v-4.02h1.93c.48,0,.86.02,1.17.04,1.12.13,1.95.58,1.95,1.97s-.95,1.88-2.08,1.99c-.31.02-.69.02-1.17.02h-1.79Z" />
                  <path d="m111.46,16.65h-7.85v-3.58h7.12v-2.7h-7.12v-3.56h7.63v-2.7h-11.1v15.26h11.32v-2.72Z" />
                  <path d="m125.3,16.65h-7.85v-3.58h7.12v-2.7h-7.12v-3.56h7.63v-2.7h-11.1v15.26h11.32v-2.72Z" />
                  <path d="m141.75,19.37V4.11h-3.45v6.79c0,1.13.02,2.85.11,4.45l-6.14-11.23h-4.45v15.26h3.47v-6.63c0-1.11-.02-2.68-.13-4.29l5.97,10.92h4.62Z" />
                  <rect x="149.07" y="4.11" width="3.47" height="15.26" />
                  <path d="m160.94,3.8c-2.97,0-5.6,1.66-5.6,4.51s1.99,4,5.26,4.82c2.08.49,2.78,1.21,2.78,2.28s-1,1.7-2.25,1.7c-1.39,0-2.61-.75-2.92-2.32h-3.49c.27,3.29,2.74,4.89,6.48,4.89,3.05,0,5.81-1.59,5.81-4.56,0-2.41-1.46-4-4.82-4.8-2.45-.57-3.23-1.2-3.23-2.34s.77-1.61,1.85-1.61c1.46,0,2.26.91,2.43,2.32h3.49c-.33-2.76-2.01-4.89-5.81-4.89h0Z" />
                  <path d="m180.69,16.45h-7.85V4.11h-3.47v15.26h11.32v-2.92Z" />
                  <path d="m191.39,15.05l1.39,4.31h3.76l-5.44-15.26h-4.29l-5.51,15.26h3.69l1.42-4.31h4.97Zm-2.48-7.94l1.62,5.22h-3.25l1.63-5.22Z" />
                  <path d="m212.16,19.37V4.11h-3.45v6.79c0,1.13.02,2.85.11,4.45l-6.15-11.23h-4.44v15.26h3.47v-6.63c0-1.11-.02-2.68-.13-4.29l5.97,10.92h4.62Z" />
                  <path d="m222.79,4.2c-.77-.09-1.13-.09-1.95-.09h-5.49v15.26h5.46c.82,0,1.17,0,1.95-.09,3.54-.42,6.39-3.03,6.39-7.74s-2.83-6.94-6.37-7.34h0Zm-.86,12.41c-.51.04-.91.04-1.55.04h-1.55V6.81h1.57c.64,0,1.04.02,1.55.07,2.15.24,3.56,1.84,3.56,4.71s-1.44,4.77-3.58,5.02h0Z" />
                  <rect x="235.82" y="4.11" width="3.47" height="15.26" />
                  <path d="m256.43,19.37V4.11h-3.45v6.79c0,1.13.02,2.85.11,4.45l-6.15-11.23h-4.45v15.26h3.47v-6.63c0-1.11-.02-2.68-.13-4.29l5.97,10.92h4.62Z" />
                  <path d="m268.19,19.37l5.51-15.26h-3.67l-3.94,12.58-3.87-12.58h-3.76l5.44,15.26h4.29Z" />
                  <path d="m286.7,16.65h-7.85v-3.58h7.12v-2.7h-7.12v-3.56h7.63v-2.7h-11.1v15.26h11.32v-2.72Z" />
                  <path d="m294.41,3.8c-2.96,0-5.59,1.66-5.59,4.51s1.99,4,5.26,4.82c2.08.49,2.79,1.21,2.79,2.28s-1,1.7-2.25,1.7c-1.39,0-2.61-.75-2.92-2.32h-3.5c.27,3.29,2.74,4.89,6.48,4.89,3.05,0,5.82-1.59,5.82-4.56,0-2.41-1.46-4-4.82-4.8-2.46-.57-3.23-1.2-3.23-2.34s.77-1.61,1.86-1.61c1.46,0,2.25.91,2.43,2.32h3.5c-.33-2.76-2.02-4.89-5.82-4.89h0Z" />
                  <path d="m315,6.81v-2.7h-13.62v2.7h5.09v12.56h3.45V6.81h5.09Z" />
                  <path d="m79.4,39.01l2.57,6.83h1.07l-6.75-17.91h-1.3l-6.83,17.91h1.07l2.51-6.83h7.66Zm-7.32-.86l3.53-9.43,3.48,9.43h-7.01Z" />
                  <path d="m102.21,27.93h-.98v11.27c0,.83,0,1.53-.06,2.08-.21,2.57-1.58,4.08-4,4.08s-4.08-1.25-4.21-4.54l-.03-.86h-.98l.02.86c.13,3.58,1.98,5.4,5.07,5.4,3.4,0,4.96-2.29,5.11-4.83.05-.85.05-1.53.05-2.31v-11.14Z" />
                  <path d="m114.26,25.02c.41,0,.74-.33.74-.71,0-.41-.33-.74-.74-.74-.38,0-.71.33-.71.74,0,.39.33.71.71.71h0Zm2.42,0c.39,0,.71-.33.71-.71,0-.41-.33-.74-.71-.74-.41,0-.74.33-.74.74,0,.39.33.71.74.71h0Zm-1.21,1.46c-5.33,0-8.68,4.15-8.68,9.86s3.35,9.86,8.68,9.86,8.65-4.18,8.65-9.86-3.35-9.86-8.65-9.86h0Zm0,18.82c-4.18,0-7.53-3.27-7.53-8.96s3.35-8.96,7.53-8.96,7.5,3.24,7.5,8.96-3.35,8.96-7.5,8.96h0Z" />
                  <path d="m134.96,45.84l6.81-17.91h-1.07l-6.39,17.11-6.36-17.11h-1.07l6.73,17.91h1.35Z" />
                  <path d="m151.89,26.2l1.76-2.5h-1.09l-1.41,2.5h.75Zm1.71,0h.75l1.76-2.5h-1.09l-1.41,2.5Zm-.7.86c-5.17,0-8.43,4.03-8.43,9.57s3.25,9.57,8.43,9.57,8.4-4.05,8.4-9.57-3.25-9.57-8.4-9.57h0Zm0,18.27c-4.05,0-7.31-3.18-7.31-8.7s3.25-8.7,7.31-8.7,7.28,3.15,7.28,8.7-3.25,8.7-7.28,8.7h0Z" />
                  <path d="m182.03,44.98h-10.36v-7.69h9.5v-.83h-9.5v-7.71h10.1v-.83h-11.09v17.91h11.35v-.86Z" />
                  <path d="m200.47,45.84l-.03-17.91h-.96v9.53c0,2.34,0,5.12.06,7.45l-10.23-16.98h-1.51v17.91h.99v-9.55c0-2.34,0-5.09-.05-7.43l10.2,16.98h1.53Z" />
                  <path d="m218.4,44.98h-10.36v-7.69h9.5v-.83h-9.5v-7.71h10.1v-.83h-11.09v17.91h11.35v-.86Z" />
                  <path d="m234.45,40.98c-.21-1.97-1.04-3.58-2.62-4.13,2.1-.44,3.56-2.02,3.56-4.34,0-2.67-1.64-4.12-4.36-4.46-.83-.1-1.61-.13-2.52-.13h-4.72v17.91h.98v-8.52h3.97c.41,0,1.01,0,1.56.03,2.08.1,2.93,1.82,3.17,3.79.29,2.49.65,3.56,1.06,4.7h1.12c-.54-1.09-.91-2.26-1.2-4.86h0Zm-9.68-4.51v-7.69h3.74c.91,0,1.56.03,2.28.13,2.26.29,3.53,1.45,3.53,3.68s-1.42,3.64-3.66,3.82c-.75.05-1.19.05-1.89.05h-4Z" />
                  <path d="m248.21,37.69v.86h5.58c0,4.93-3.5,6.8-6.26,6.8-3.84,0-6.91-2.88-6.91-8.46s3.38-8.47,7.22-8.47c3.43,0,5.43,2.11,6,4.93l.05.26h.98l-.05-.26c-.57-3.27-2.81-5.79-6.88-5.79-4.86,0-8.41,3.82-8.41,9.37s3.22,9.27,7.92,9.27c2.24,0,4.44-.88,5.82-3.04l.6-1.66v4.34h.91v-8.15h-6.57Z" />
                  <rect x="260.57" y="27.93" width=".99" height="17.91" />
                  <path d="m274.19,26.2l1.76-2.51h-1.09l-1.41,2.51h.75Zm3.57,12.62l2.64,7.02h1.09l-6.94-18.41h-1.33l-7.02,18.41h1.09l2.59-7.02h7.87Zm-7.52-.88l3.63-9.68,3.57,9.68h-7.2Z" />
                  <path d="m295.64,27.93h-.99v11.27c0,.83,0,1.53-.05,2.08-.21,2.57-1.59,4.08-4,4.08s-4.07-1.25-4.2-4.54l-.03-.86h-.99l.03.86c.13,3.58,1.97,5.4,5.06,5.4,3.4,0,4.96-2.29,5.12-4.83.05-.85.05-1.53.05-2.31v-11.14Z" />
                  <path d="m311.36,39.01l2.57,6.83h1.07l-6.75-17.91h-1.3l-6.83,17.91h1.07l2.52-6.83h7.66Zm-7.32-.86l3.53-9.43,3.48,9.43h-7.01Z" />
                  <path d="m43.08,59.24h-10.77c-14.85,0-26.92-12.08-26.92-26.93S17.46,5.39,32.31,5.39h5.38V0h-5.38C14.5,0,0,14.5,0,32.31s14.5,32.31,32.31,32.31h16.16v-21.54h-5.39v16.16Zm-10.77-43.08h16.16V0h-5.39v10.77h-10.77c-11.87,0-21.54,9.67-21.54,21.54s9.67,21.54,21.54,21.54h5.38v-16.16h10.77v-5.38h-16.16v16.16c-8.91,0-16.16-7.25-16.16-16.16s7.24-16.16,16.16-16.16h0Z" />
                </g>
              </g>
            </svg>
          </span>
        </div>

        {!isTablet && (
          <>
            <div className="header__content__menu">
              <Menu />
            </div>

            <div className="header__content__submenu">
              <Submenu />
            </div>
          </>
        )}
        {isTablet && <MobileNav />}
      </div>
    </div>
  );
};

const MobileNav = () => {
  const { isMenuShown, setIsMenuShown } = useContext(MenuContext);

  return (
    <div className={`header__content__mobnav ${isMenuShown ? "active" : ""}`}>
      {!isMenuShown && (
        <span className="menu" onClick={setIsMenuShown}>
          Menü
        </span>
      )}
      {isMenuShown && (
        <>
          <CloseIcon
            style={{ cursor: "pointer" }}
            fontSize="medium"
            onClick={setIsMenuShown}
          />

          <motion.div
            className="header__content__mobnav__nav"
            initial={{
              x: "100%",
            }}
            animate={{ x: 0 }}
            transition={{ duration: 0.5 }}>
            <Menu />
            <Submenu />
          </motion.div>
        </>
      )}
    </div>
  );
};

const Menu = () => {
  const location = useLocation();
  let lakossagiGomb;
  if (location.pathname === "/aszf") {
    lakossagiGomb = <></>;
  } else {
    lakossagiGomb = (
      <a
        href={
          location.pathname === "/" || location.pathname === "/lakossagi"
            ? "/vallalati"
            : "/lakossagi"
        }>
        {location.pathname === "/" || location.pathname === "/lakossagi"
          ? "Vállalati"
          : "Lakossági"}
      </a>
    );
  }
  return location.pathname !== "/aszf" &&
    !location.pathname.includes("/blog") ? (
    <ul>
      {navItems.map((item, index) => (
        <li key={index}>
          <a href={item.path}>{item.name}</a>
        </li>
      ))}
      <li>{lakossagiGomb}</li>
    </ul>
  ) : (
    <ul>
      <li key="fooldal">
        <Link to="/#top">Vissza a főoldalra</Link>
      </li>
    </ul>
  );
};

const Submenu = () => {
  return (
    <ul>
      <li>
        <a
          href="https://www.facebook.com/greenislandinvestzrt"
          target="_blank"
          rel="noreferrer">
          <FacebookRoundedIcon />
        </a>
      </li>
      <li>
        <a
          href="https://www.instagram.com/greenislandinvest"
          target="_blank"
          rel="noreferrer">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 24 24"
            className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root">
            <path
              fill="currentColor"
              d="M7.8 2h8.4C19.4 2 22 4.6 22 7.8v8.4a5.8 5.8 0 0 1-5.8 5.8H7.8C4.6 22 2 19.4 2 16.2V7.8A5.8 5.8 0 0 1 7.8 2m-.2 2A3.6 3.6 0 0 0 4 7.6v8.8C4 18.39 5.61 20 7.6 20h8.8a3.6 3.6 0 0 0 3.6-3.6V7.6C20 5.61 18.39 4 16.4 4zm9.65 1.5a1.25 1.25 0 0 1 1.25 1.25A1.25 1.25 0 0 1 17.25 8A1.25 1.25 0 0 1 16 6.75a1.25 1.25 0 0 1 1.25-1.25M12 7a5 5 0 0 1 5 5a5 5 0 0 1-5 5a5 5 0 0 1-5-5a5 5 0 0 1 5-5m0 2a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3"
            />
          </svg>
        </a>
      </li>
      <li>
        <a
          href="https://www.tiktok.com/@greenislandinvest"
          target="_blank"
          rel="noreferrer">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 24 24"
            className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root">
            <path
              fill="currentColor"
              d="M16.6 5.82s.51.5 0 0A4.278 4.278 0 0 1 15.54 3h-3.09v12.4a2.592 2.592 0 0 1-2.59 2.5c-1.42 0-2.6-1.16-2.6-2.6c0-1.72 1.66-3.01 3.37-2.48V9.66c-3.45-.46-6.47 2.22-6.47 5.64c0 3.33 2.76 5.7 5.69 5.7c3.14 0 5.69-2.55 5.69-5.7V9.01a7.35 7.35 0 0 0 4.3 1.38V7.3s-1.88.09-3.24-1.48"
            />
          </svg>
        </a>
      </li>
      <li>
        <a
          href="https://www.linkedin.com/company/green-island-invest-hungary/"
          target="_blank"
          rel="noreferrer">
          <LinkedInIcon />
        </a>
      </li>
      <li>
        <a
          href="https://www.youtube.com/@GreenIslandInvestZrt."
          target="_blank"
          rel="noreferrer">
          <YouTube />
        </a>
      </li>
    </ul>
  );
};

export default Header;
