import React from "react";
import { getCookieConsentValue } from "react-cookie-consent";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga4";
import { Route, Routes as Switch, useLocation } from "react-router-dom";
import Arajanlatozo from "../pages/arajanlatozo";
import ASZF from "../pages/Aszf";
import Bejelentkezes from "../pages/Bejelentkezes";
import Blog from "../pages/Blog";
import NotFound from "../pages/NotFound";
import Solar from "../pages/Solar";

const Routes = () => {
  const advancedMatching = null;
  const options = {
    autoConfig: true,
    debug: false,
  };
  const history = useLocation();

  if (history.pathname !== "/bejelentkezes") {
    ReactPixel.init("1272722737192844", advancedMatching, options);
    ReactGA.initialize("G-959V9P9RTK");

    if (getCookieConsentValue("sutiBeleegyezes") === "true") {
      ReactPixel.pageView();
      ReactGA.send({
        hitType: "pageview",
        page: `${history.pathname}${history.hash}`,
        title: "",
      });
    } else {
      ReactPixel.revokeConsent();
      ReactGA.gtag("consent", "default", {
        ad_storage: "denied",
        ad_user_data: "denied",
        ad_personalization: "denied",
        analytics_storage: "denied",
      });
    }
  }
  return (
    <Switch>
      <Route path="/" element={<Solar />} />
      <Route path="/vallalati" element={<Solar />} />
      <Route path="/lakossagi" element={<Solar />} />
      <Route path="/aszf" element={<ASZF />} />
      <Route path="/blog/:id" element={<Blog />} />
      <Route path="/bejelentkezes" element={<Bejelentkezes />} />
      <Route
        path="/f9a3c2dd20d43eda5d3e522ba2b79dfc"
        element={<Arajanlatozo />}
      />

      <Route path="*" element={<NotFound />} />
    </Switch>
  );
};

export default Routes;
