import React from "react";
import CookieConsent from "react-cookie-consent";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga4";
import { BrowserRouter } from "react-router-dom";
import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import Routes from "./config/Routes";
import { MenuContextProvider } from "./contexts/menu-context";
import { ModelContextProvider } from "./contexts/model-context";

const App = () => {
  return (
    <BrowserRouter>
      <MenuContextProvider>
        <ModelContextProvider>
          <Header />
          <Routes />
          <Footer />
          <CookieConsent
            acceptOnScroll={true}
            acceptOnScrollPercentage={4}
            onAccept={(acceptedByScrolling) => {
              if (acceptedByScrolling) {
                ReactPixel.grantConsent();
              } else {
                ReactPixel.grantConsent();
              }
              ReactGA.gtag("consent", "default", {
                ad_storage: "granted",
                ad_user_data: "granted",
                ad_personalization: "granted",
                analytics_storage: "granted",
              });
              ReactPixel.pageView();
              ReactGA.send({
                hitType: "pageview",
                page: "/",
                title: "Landing Page",
              });
              document.getElementById("sutiNemElfogadva").id = "sutiElfogadva";
            }}
            buttonText="Elfogadom"
            cookieName="sutiBeleegyezes"
            style={{ background: "#000000" }}
            buttonStyle={{
              color: "white",
              background: "#000000",
              border: "1px solid white",
              borderRadius: "5px",
              textTransform: "none",
            }}
            expires={120}>
            Az oldal használatával hozzájárulsz a cookie-k alkalmazásához.
          </CookieConsent>
        </ModelContextProvider>
      </MenuContextProvider>
    </BrowserRouter>
  );
};

export default App;
